import { handleLogin, handleRegister } from '../../utils/handleNavbarLinks';
import Button from '@mui/material/Button';
import Logo from '../../images/logo.png';
// import Typography from '@mui/material/Typography';

const Navbar = () => {
	return (
		<header id='navbar'>
			<div className='navigation-items'>
				<div className='logo'>
					<img src={Logo} alt='Poll Magic Logo' />
				</div>
				<div className='menu-options'>
					<Button
						variant='text'
						className='sign-in-button'
						size='large'
						onClick={handleLogin}
					>
						Sign In
					</Button>
					<Button
						variant='text'
						className='sign-up-button'
						onClick={handleRegister}
						size='large'
					>
						Get Started
					</Button>
				</div>
			</div>
		</header>
	);
};

export default Navbar;
