import { Route, Switch } from 'react-router-dom';
import Home from './views/Home';
import Navbar from './components/navigation/Navbar';

function App() {
	return (
		<div className='App'>
			<Navbar />
			<Switch>
				<Route exact to='/' component={Home} />
			</Switch>
		</div>
	);
}

export default App;
