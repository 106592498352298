const Chart = ({ chartUpdate }) => {
	return (
		<iframe
			key={chartUpdate}
			title='PollMagic Chart'
			src='https://app.pollmagic.io/chart/626f3e30118911103d394bd2'
			frameBorder='0'
			width={900}
			height={245}
		></iframe>
	);
};

export default Chart;
