import { NoButton, YesButton } from './elements/ChartButtons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from './elements/Chart';

const LearnMoreSection = () => {
	const [chartUpdate, setChartUpdate] = useState(1);

	const pageLinks = document.getElementsByTagName('a');

	const url =
		'https://app.pollmagic.io/api/polls/external/626f3e30118911103d394bd2';

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	const handleClick = (e) => {
		e.preventDefault();

		const sendRequest = (data) => {
			axios
				.put(url, data, config)
				.then((res) => {
					console.log(`response: ${res.status}`);
					// reload chart
					setChartUpdate(Date());
				})
				.catch((err) => console.error(err));
		};

		if (e.target.innerText === 'ADD A "YES" VOTE') {
			const data = { newYesVote: true, newNoVote: false };
			sendRequest(data);
		}

		if (e.target.innerText === 'ADD A "NO" VOTE') {
			const data = { newYesVote: false, newNoVote: true };
			sendRequest(data);
		}

		if (
			e.target.innerText === 'Join For Free' ||
			e.target.innerText === 'Click Here To Join For Free'
		) {
			window.location.replace('https://app.pollmagic.io/register');
		}
	};

	useEffect(() => {
		for (let e = 0; e < pageLinks.length; e++)
			pageLinks[e].addEventListener('click', handleClick);
		// eslint-disable-next-line
	}, []);

	return (
		<div className='learn-more-section'>
			<div className='poll-funnel-images'>
				<img
					src='https://res.cloudinary.com/fullstackcaveman/image/upload/v1651805666/PollMagic/poll_magic_salespage_poll_pics_wz8br3.png'
					alt='PollMagic Example Funnel'
				/>
			</div>
			<div className='chart'>
				<Chart chartUpdate={chartUpdate} />
			</div>
			<div className='buttons'>
				<div className='button'>
					<YesButton variant='contained' color='primary' component='a'>
						ADD A "YES" VOTE
					</YesButton>
				</div>
				<div className='button'>
					<NoButton variant='contained' color='secondary' component='a'>
						ADD A "NO" VOTE
					</NoButton>
				</div>
			</div>
		</div>
	);
};

export default LearnMoreSection;
