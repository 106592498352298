import { handleRegister } from '../../utils/handleNavbarLinks';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';

const HeroSection = () => {
	return (
		<section className='hero-section'>
			<div className='hero-items'>
				<div className='hero-card'>
					<Typography variant='h1'>
						Polling tool that boosts email lists.
					</Typography>
					<div className='sub-headline'>
						<Typography variant='h4'>
							A tool to build email lists, offer products and services, and
							engage with your audience in just 5 minutes. Get started now. No
							payment required.
						</Typography>
					</div>
					<div className='checkits'>
						<div className='check-item'>
							<span>
								<CheckIcon fontSize='large' />
							</span>
							<Typography variant='h6'>Free and paid plans</Typography>
						</div>
						<div className='check-item'>
							<span>
								<CheckIcon fontSize='large' />
							</span>
							<Typography variant='h6'>Set up in minutes</Typography>
						</div>
						<div className='check-item'>
							<span>
								<CheckIcon fontSize='large' />
							</span>
							<Typography variant='h6'>No credit card required</Typography>
						</div>
					</div>
					<div className='action-section'>
						<Button variant='contained' size='large' onClick={handleRegister}>
							<Typography>Get Started</Typography>
						</Button>
					</div>
				</div>
				<div className='pollers'>
					<img
						src='https://res.cloudinary.com/fullstackcaveman/image/upload/v1652233109/PollMagic/hero_section_image_vlsjrv.png'
						alt='poll magic'
					/>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;
