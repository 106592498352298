import HeroSection from '../components/sections/HeroSection';
import LearnMoreSection from '../components/sections/LearnMoreSection';
import '../main.css';

const Home = () => {
	return (
		<section className='home'>
			<HeroSection />
			<LearnMoreSection />
		</section>
	);
};

export default Home;
